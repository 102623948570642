
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { MedervaLayout } from "@ui/components/MedervaLayout";
import { Box, Img, SEO, Stack, Text, useColorModeValue } from "@ui/index";
import NextLink from "next/link";
const NotFoundPage = () => {
    return (<MedervaLayout>
      <SEO title="Error 404: Page not found!"/>
      <Stack h="full" color={useColorModeValue("gray.850", "gray.300")} textAlign={"center"} alignContent={"center"} alignItems={"center"} w={"full"}>
        <Box my="auto">
          <Img src={`/icon-404.svg`} mx="auto" width="24" alt="Icon of Skull"/>
          <Text my="auto" fontSize={{ base: "6xl", lg: "9xl" }} fontWeight="black">
            404
          </Text>
          <Text fontSize={{ base: "md", lg: "lg" }} fontWeight="medium">
            The page you’re looking for doesn’t exist.
          </Text>
          <NextLink href="/">
            <Box color="green.500" _hover={{ textDecoration: "underline" }}>
              Return Home
            </Box>
          </NextLink>
        </Box>
      </Stack>
    </MedervaLayout>);
};
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__195f951eba6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f951eba6__ as getStaticProps }
  